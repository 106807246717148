class inputHide extends Component {

    static name() {
        return "input-hide";
    }

    static componentName() {
        return "input-hide";
    }

    getProps() {
        return  ['def', 'value', 'custom_css_classes'];
    }

    data() {
        return {
            isSet: false,
        };
    }

    getComputed() {
        return {
            id:function () {
                return this.getUI()
            },
            getDef: function () {
                let defaults = {visibility: true, readonly: false, pastewindow: null, fillpastewindow: null}
                Object.assign(defaults, this.def)
                return defaults;
            },
        };
    }

    getMethods() {
        return {
            focus() {
                this.$refs.input.focus()
            }
        };
    }


    getTemplate() {
        return `<input :disabled="getDef.readonly" type="hidden" :id="id" placeholder="" :value="value" @input="$emit('input',$event)" ref="input" >`;
    }
}

inputHide.registerComponent();


